import type { ProductSearchType } from "~/models/products";

export function getProductListName(searchType: ProductSearchType): string {
  let productListName = "Results";

  switch (searchType) {
    case "domain":
      productListName = "Domain";
      break;
    case "promotion":
      productListName = "Promotion";
      break;
  }

  return productListName;
}
