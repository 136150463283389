import { SortableFields, SortOrder } from "~/graphql/propeller/generated";
import type {
  ActiveRangeFiltersType,
  ActiveTextFiltersType,
  FilterGroupMultiCheck,
  FilterGroupRange,
  FilterOptionMultiCheck,
  PriceFilter,
} from "~/models/filters";
import type { ProductListItem, ProductSearchQueryParams, ProductSearchType } from "~/models/products";
import type { SearchProductsRequest, SearchProductsResponse } from "~/server/api/ecom/products/search.get";

interface CatalogQueryParams {
  page?: number | null;
  categoryId?: number | null;
  term?: string | null;
  textFilters?: string | null;
  rangeFilters?: string | null;
  priceFilter?: string | null;
  sortField?: string | null;
  sortOrder?: string | null;
}

const _defaultPage = 1;

function getTextFiltersAsJson(textFilters: string) {
  try {
    return JSON.parse(textFilters) as ActiveTextFiltersType;
  }
  catch (e) {
    console.warn("Invalid text filters in query string", e);
    return undefined;
  }
}

function getRangeFiltersAsJson(rangeFilters: string) {
  try {
    return JSON.parse(rangeFilters) as ActiveRangeFiltersType;
  }
  catch (e) {
    console.warn("Invalid range filters in query string", e);
    return undefined;
  }
}

function getPriceFilterAsJson(priceFilter: string) {
  try {
    return JSON.parse(priceFilter) as PriceFilter;
  }
  catch (e) {
    console.warn("Invalid price filter in query string", e);
    return undefined;
  }
}

export async function useCatalog(domainName?: string | null) {
  const { trackProductList, trackSearch } = useGtmTracking();
  const { locale, t } = useI18n();
  const { meta, params: routeParams } = useRoute();
  const { push } = useRouter();
  const localePath = useLocalePath();

  const searchType = computed(() => meta.catalogSearchType ?? "catalog");
  // console.log("Entered useCatalog", fullPath, meta.catalogSearchType, params.slug);

  // below states need to be global states so every instance of this composable works with the same values
  const catalogProducts = useCatalogProducts();
  const catalogPrices = useCatalogPrices();
  const catalogTextFilters = useCatalogTextFilters();
  const catalogRangeFilters = useCatalogRangeFilters();
  const catalogPriceFilter = useCatalogPriceFilter();
  const catalogPagination = useCatalogPagination();
  const catalogSearchQuery = useCatalogSearchQuery();

  const { status, execute, error } = await useFetch("/api/ecom/products/search", {
    // server: false,
    lazy: true,
    immediate: false,
    dedupe: "defer",
    query: computed(() =>
      ({
        ...catalogSearchQuery.value,
        searchType: searchType.value,
        locale: locale.value,
      } as SearchProductsRequest),
    ),
    watch: false,
    deep: false,
    onRequest: ({ request, options }) => {
      // eslint-disable-next-line no-console
      console.log(`fetch (request: ${JSON.stringify(request)} | query: ${JSON.stringify(options.query)})`);
    },
    onResponse: async ({ response }) => {
      const responseData = response._data as SearchProductsResponse;

      // console.log("fetch products response", responseData);
      catalogTextFilters.value = await mapToTextFilters(responseData, searchType.value.toString(), t);

      catalogRangeFilters.value = await mapToRangeFilters(
        responseData,
        searchType.value.toString(),
        t,
        catalogRangeFilters.value,
        catalogSearchQuery.value.rangeFilters,
      );

      catalogProducts.value = responseData.products as ProductListItem[];

      catalogPagination.value.pageSize = responseData.pagination?.pageSize ?? 0;
      catalogPagination.value.totalItems = responseData.pagination?.totalItems ?? 0;

      // min and max values change with every request, so preventing those updates with extra check
      if (catalogPrices.value.min === 0 && catalogPrices.value.max === 0) {
        catalogPrices.value = {
          min: responseData.prices?.min ?? 0,
          max: responseData.prices?.max ?? 0,
        };
      }

      catalogPriceFilter.value = {
        from: catalogSearchQuery.value.priceFilter?.from ?? responseData.prices?.min ?? 0,
        to: catalogSearchQuery.value.priceFilter?.to ?? responseData.prices?.max ?? 0,
      };

      if (catalogProducts.value && catalogProducts.value.length > 0) {
        const productListName = getProductListName(meta.catalogSearchType as ProductSearchType);
        trackProductList(catalogProducts.value, productListName);
      }
    },
    onResponseError: ({ response }) => {
      console.error("Could not get catalog data", response);
    },
  });

  const setParamsAndExecute = async (params: CatalogQueryParams) => {
    catalogSearchQuery.value = {
      ...catalogSearchQuery.value,
      page: params.page || _defaultPage,
      sortField: params.sortField ? mapToSortField(params.sortField) : SortableFields.name,
      sortOrder: params.sortOrder ? mapToSortOrder(params.sortOrder) : SortOrder.ASC,
      categoryId: params.categoryId || undefined,
      term: params.term || undefined,
      textFilters: params.textFilters ? getTextFiltersAsJson(params.textFilters) : undefined,
      rangeFilters: params.rangeFilters ? getRangeFiltersAsJson(params.rangeFilters) : undefined,
      priceFilter: params.priceFilter ? getPriceFilterAsJson(params.priceFilter) : undefined,
    };

    await execute();
  };

  const determineNavigationUrl = (params: CatalogQueryParams) => {
    const queryParams: ProductSearchQueryParams = {
      page: _defaultPage,
      sortField: SortableFields.relevance,
      sortOrder: SortOrder.ASC,
    };

    if (params.categoryId) {
      queryParams.categoryId = params.categoryId;
    }

    if (params.term) {
      queryParams.term = params.term;
      queryParams.sortField = SortableFields.relevance;
      queryParams.sortOrder = SortOrder.DESC;
      trackSearch(params.term);
    }

    if (params.textFilters) {
      queryParams.textFilters = params.textFilters;
    }

    if (params.rangeFilters) {
      queryParams.rangeFilters = params.rangeFilters;
    }

    if (params.priceFilter) {
      queryParams.priceFilter = params.priceFilter;
    }

    let basePath = "/";

    switch (searchType.value) {
      case "catalog": {
        const categoryPath = routeParams.category ? `/${routeParams.category}` : "";
        basePath = `/catalog${categoryPath}`;
        break;
      }
      case "domain":
        basePath = localePath({ name: "domains-slug", params: { slug: domainName } });
        break;
      case "promotion":
        basePath = "/promotion";
        break;
    }

    return localePath(`${basePath}?${new URLSearchParams(queryParams as any).toString()}`);
  };

  const setParamsAndNavigate = async (params: CatalogQueryParams) => {
    const navigationUrl = determineNavigationUrl(params);
    await navigateTo(navigationUrl);
  };

  const setParamsAndReload = async (params: CatalogQueryParams) => {
    const navigationUrl = determineNavigationUrl(params);
    document.location.href = navigationUrl;
  };

  // when a search param gets updated in the state, reflect the change in the url query params
  watch(catalogSearchQuery, (newSearchQuery) => {
    // console.log("searchQuery changed");

    let newQueryParams: ProductSearchQueryParams = {
      page: newSearchQuery.page,
      sortField: newSearchQuery.sortField,
      sortOrder: newSearchQuery.sortOrder!,
    };

    if (newSearchQuery.categoryId) {
      newQueryParams = {
        ...newQueryParams,
        categoryId: newSearchQuery.categoryId,
      };
    }

    if (newSearchQuery.term && newSearchQuery.term.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        term: newSearchQuery.term.toString(),
      };
    }

    if (newSearchQuery.textFilters && newSearchQuery.textFilters.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        textFilters: JSON.stringify(newSearchQuery.textFilters),
      };
    }

    if (newSearchQuery.rangeFilters && newSearchQuery.rangeFilters.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        rangeFilters: JSON.stringify(newSearchQuery.rangeFilters),
      };
    }

    if (newSearchQuery.priceFilter) {
      newQueryParams = {
        ...newQueryParams,
        priceFilter: JSON.stringify(newSearchQuery.priceFilter),
      };
    }

    push({
      query: {
        page: newQueryParams.page,
        sortField: newQueryParams.sortField,
        sortOrder: newQueryParams.sortOrder,
        categoryId: newQueryParams.categoryId ?? undefined,
        term: newQueryParams.term ?? undefined,
        textFilters: newQueryParams.textFilters ?? undefined,
        rangeFilters: newQueryParams.rangeFilters ?? undefined,
        priceFilter: newQueryParams.priceFilter ?? undefined,
      },
    });
  }, {
    deep: true,
  });

  watch(() => catalogSearchQuery.value.page, () => document.body.scrollIntoView({ behavior: "smooth" }));

  const page = computed({
    get: () => {
      return catalogSearchQuery.value.page;
    },
    set: async (newPage: number) => {
      catalogSearchQuery.value.page = newPage;

      await execute();
    },
  });

  const sortValue = computed({
    get: () => {
      return `${catalogSearchQuery.value.sortField}-${catalogSearchQuery.value.sortOrder}`;
    },
    set: async (newSortField: string) => {
      const splitValues = newSortField.split("-");
      catalogSearchQuery.value.sortField = mapToSortField(splitValues.at(0)!);
      catalogSearchQuery.value.sortOrder = mapToSortOrder(splitValues.at(1)!);
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const textFilters = computed<ActiveTextFiltersType | undefined > ({
    get: () => {
      return catalogSearchQuery.value.textFilters;
    },
    set: async (newTextFilters?: ActiveTextFiltersType) => {
      if (catalogSearchQuery.value.textFilters === newTextFilters) {
        return;
      }

      catalogSearchQuery.value.textFilters = newTextFilters;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const rangeFilters = computed<ActiveRangeFiltersType | undefined > ({
    get: () => {
      return catalogSearchQuery.value.rangeFilters;
    },
    set: async (newRangeFilters?: ActiveRangeFiltersType) => {
      if (catalogSearchQuery.value.rangeFilters === newRangeFilters) {
        return;
      }

      catalogSearchQuery.value.rangeFilters = newRangeFilters;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const priceFilter = computed<PriceFilter | undefined > ({
    get: () => {
      return catalogSearchQuery.value.priceFilter;
    },
    set: async (newPriceFilter?: PriceFilter) => {
      if (catalogSearchQuery.value.priceFilter === newPriceFilter) {
        return;
      }

      catalogSearchQuery.value.priceFilter = newPriceFilter;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const categoryId = computed<number | undefined>({
    get: () => {
      return catalogSearchQuery.value.categoryId;
    },
    set: async (newCategoryId?: number) => {
      if (catalogSearchQuery.value.categoryId === newCategoryId) {
        return;
      }

      catalogSearchQuery.value.categoryId = newCategoryId;
      catalogSearchQuery.value.textFilters = undefined;
      catalogSearchQuery.value.priceFilter = undefined;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const term = computed<string | undefined>({
    get: () => {
      return catalogSearchQuery.value.term;
    },
    set: async (newTerm?: string) => {
      if (catalogSearchQuery.value.term === newTerm) {
        return;
      }

      catalogSearchQuery.value.term = newTerm;
      catalogSearchQuery.value.textFilters = undefined;
      catalogSearchQuery.value.priceFilter = undefined;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const hasItems = computed<boolean>(() => {
    return catalogPagination.value.totalItems > 0;
  });

  const activeTextFilterOptions = computed<{ title: string; option: FilterOptionMultiCheck }[]>(() => {
    return catalogTextFilters.value
      .filter((fg: FilterGroupMultiCheck) => !fg.isHiddenInUI)
      .reduce((acc: {
        title: string;
        option: FilterOptionMultiCheck;
      }[], { title, options }: { title: string; options: Array<FilterOptionMultiCheck> }) => {
        options && options.forEach((option: FilterOptionMultiCheck) =>
          acc.push({
            title: title,
            option: option,
          }),
        );

        return acc;
      }, [])
      .filter((filterOption: any) => filterOption.option.selected);
  });

  const activeRangeFilterOptions = computed<{ searchId: string; title: string; from: number; to: number }[]>(() => {
    return catalogRangeFilters.value
      .filter((fg: FilterGroupRange) => !fg.isHiddenInUI && (fg.from > 0 || fg.to > 0))
      .map((fg: FilterGroupRange) => {
        return {
          searchId: fg.searchId,
          title: fg.title,
          from: fg.from,
          to: fg.to,
        };
      });
  });

  const updateTextFilters = () => {
    const newTextFilters = [];

    for (const filterGroup of catalogTextFilters.value) {
      if (filterGroup.options.filter((o: FilterOptionMultiCheck) => o.selected).length > 0) {
        newTextFilters.push({
          searchId: filterGroup.searchId,
          type: filterGroup.type,
          values: filterGroup.options
            .filter((o: FilterOptionMultiCheck) => o.selected)
            .map((o: FilterOptionMultiCheck) => o.value),
        });
      }
    }

    textFilters.value = newTextFilters as any;
  };

  const updateRangeFilters = (filter: { searchId: string; model: Array<number> }) => {
    const newRangeFilters = [];

    const catalogRangeFilter = catalogRangeFilters.value
      .find((rf: FilterGroupRange) => rf.searchId === filter.searchId);

    if (catalogRangeFilter) {
      catalogRangeFilter.from = filter.model.at(0) ?? 0;
      catalogRangeFilter.to = filter.model.at(1) ?? 0;
    }

    for (const filterGroup of catalogRangeFilters.value) {
      if (filterGroup.from > filterGroup.min || filterGroup.to < filterGroup.max) {
        newRangeFilters.push({
          searchId: filterGroup.searchId,
          type: filterGroup.type,
          from: filterGroup.from,
          to: filterGroup.to,
        });
      }
    }

    rangeFilters.value = newRangeFilters as any;
  };

  const updatePriceFilter = (filter: { _: never; model: Array<number> }) => {
    priceFilter.value = {
      from: filter.model.at(0) ?? 0,
      to: filter.model.at(1) ?? 0,
    };
  };

  function resetCatalog() {
    const searchTerm = useSearchTerm();
    searchTerm.value = "";

    setParamsAndExecute({
      term: undefined,
      page: _defaultPage,
      sortField: SortableFields.categoryOrder,
      sortOrder: SortOrder.ASC,
    });
  }

  return {
    pending: status.value === "pending",
    error: error,
    hasItems: hasItems,
    products: catalogProducts,
    prices: catalogPrices,
    textFilters: catalogTextFilters,
    rangeFilters: catalogRangeFilters,
    priceFilter: catalogPriceFilter,
    pagination: catalogPagination,
    page: page,
    sortValue: sortValue,
    categoryId: categoryId,
    term: term,
    searchType: searchType,
    activeTextFilters: textFilters,
    activeTextFilterOptions: activeTextFilterOptions,
    activeRangeFilters: rangeFilters,
    activeRangeFilterOptions: activeRangeFilterOptions,
    updateTextFilters: updateTextFilters,
    updateRangeFilters: updateRangeFilters,
    updatePriceFilter: updatePriceFilter,
    setParamsAndExecute: setParamsAndExecute,
    setParamsAndNavigate: setParamsAndNavigate,
    setParamsAndReload: setParamsAndReload,
    resetCatalog: resetCatalog,
  };
}
