/**
 * These identifiers could change in future syncs...
 *  Sub-categories get an extra digit as sort order.
 */
export const CategorySlugList: any = {
  "accessoires": { id: 2510, name: "Accessoires", sortOrder: 61 },
  "alcoholvrij": { id: 2492, name: "Alcoholvrij", sortOrder: 4 },
  "andere": { id: 2511, name: "Andere", sortOrder: 63 },
  "aperitieven-en-porto": { id: 2495, name: "Aperitieven en porto", sortOrder: 5 },
  "bubbels": { id: 2501, name: "Bubbels", sortOrder: 1 },
  "diversen": { id: 2509, name: "Diversen", sortOrder: 6 },
  "olijfolie": { id: 2512, name: "Olijfolie", sortOrder: 62 },
  "sterke-dranken": { id: 2513, name: "Sterke dranken", sortOrder: 3 },
  "wijn": { id: 2530, name: "Wijn", sortOrder: 2 },
};

// TODO: might come in handy later on...
// export const CategoryAllowFilterList: any = {
//   2016: { name: "Accessoires", filters: [{ searchId: "", sortOrder: "" }] },
//   1996: { name: "Alcoholvrij", filters: [{ searchId: "", sortOrder: "" }] },
//   2017: { name: "Andere", filters: [{ searchId: "", sortOrder: "" }] },
//   // 1997: { name: "Aperitieven en porto", filters: [{ searchId: "", sortOrder: "" }] },
//   1998: {
//     name: "Bubbels",
//     filters: [
//       { searchId: "attr_bc_afsluiting", sortOrder: 1 },
//       { searchId: "attr_bc_land", sortOrder: 2 },
//       { searchId: "attr_bc_domein", sortOrder: 3 },
//     ],
//   },
//   1999: { name: "Diversen", filters: [{ searchId: "", sortOrder: "" }] },
//   2018: { name: "Olijfolie", filters: [{ searchId: "", sortOrder: "" }] },
//   2000: { name: "Sterke dranken", filters: [{ searchId: "", sortOrder: "" }] },
//   2001: { name: "Wijn", filters: [{ searchId: "", sortOrder: "" }] },
// };

export interface CategoryListItem {
  id: number;
  name: string;
  slug: string;
}
