import { SortableFields as SF, SortOrder } from "~/graphql/propeller/generated";

const fieldStringToEnum: Map<string, ProductSortField> = new Map([
  [SF.name, SF.name],
  [SF.relevance, SF.relevance],
  [SF.categoryOrder, SF.categoryOrder],
  [SF.price, SF.price],
]);

const orderStringToEnum: Map<string, ProductSortOrder> = new Map([
  [SortOrder.ASC, SortOrder.ASC],
  [SortOrder.DESC, SortOrder.DESC],
]);

export function mapToSortField(field: string): ProductSortField {
  return (fieldStringToEnum.get(field) ?? SF.name) as ProductSortField;
}

export function mapToSortOrder(order: string): ProductSortOrder {
  return (orderStringToEnum.get(order) ?? SortOrder.ASC) as ProductSortOrder;
}

export type ProductSortField = SF.relevance | SF.categoryOrder | SF.name | SF.price;
export type ProductSortOrder = SortOrder.ASC | SortOrder.DESC;
